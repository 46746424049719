<template>
<b-container 
  fluid class="h-100 px-0"
    style="min-height:100vh;"
    @click="goTo('home')">
  <div 
    class="h-100"
    :class="showTree ? 'content-login-three': 'content-login-smart'"
    style="min-height:100vh;"
  >
    <b-row cols-lg="3" cols-md="2"  cols-sm="2" class="justify-content-center h-100 mx-0" style="z-index: 1001;
    position: relative;">
      <b-col align-self="center">
          <b-img
            style="width: 40px;height: 40px; position: absolute; right: 1rem; top: 1rem;"
            alt="Title logo"
            :src="require('@/assets/icon-back-x.png')"
            @click="goTo('home')"
          ></b-img>
          <div
            v-if="showTree"
          >
            <b-card class="card-login mt-4">
              <b-img
                fluid
                alt="Title logo"
                :src="require('@/assets/login-logo.png')"
                style="height:10rem;"
              ></b-img>
              <!-- <h4 class="txt-white mt-4">Verifikasi OTP</h4> -->
              <span class="txt-white d-block mt-3 font-weight-bold" style="font-size: 1.1rem;">Mainkan gamenya dan dapatkan rewardnya!</span>
            </b-card>
          </div>
          <div
            v-else
          >
            <b-card class="card-login mt-4">
              <b-img
                fluid
                alt="Title logo"
                :src="require('@/assets/login-logo.png')"
                style="width:75vw;"
              ></b-img>
              <!-- <h4 class="txt-white mt-4">Verifikasi OTP</h4> -->
              <span class="txt-white d-block mt-2 font-weight-bold" style="font-size: 1.3rem;">Cari Cuan Cara Instan,<br/> Ambil REWARDnya<br/>SEKARANG!</span>
            </b-card>
          </div>
        <!-- <b-card-body class="card-login py-1 px-5">
          <b-img
            fluid
            alt="Title logo"
            :src="require('@/assets/reward.png')"
          ></b-img>
        </b-card-body> -->
      </b-col>
    </b-row>
    <b-img
                fluid
                alt="Title logo"
                :src="require('@/assets/pesta2.png')"
                class="const-boot"
                style="width:90vw;"
              ></b-img>
  </div>
</b-container>
</template>

<script>
// import { mapActions } from 'vuex';
// import { apiPortal } from '@/provider'
export default {
    data() {
        return {
            msisdn : this.$store.state.user,
            telco : this.$store.state.telco,
            showTree: true,
            // token : null,
            // appkey:  this.$store.state.appKey,
            // form : {
            //     otp : null
            // }
        }
    },
    mounted() {
      if(this.telco === 'smart') this.showTree = false;
      // this.getBanner()
    },
    methods : {
        goTo(page) {
            this.$router.push({ name: page }).catch();
        },
        // ...mapActions([
        //   'getBanner'
        // ]),
    }
}
</script>

<style scoped>
    .content-login-three{
        background: url("../assets/backroundback.svg");
        background-size: cover;
        overflow: hidden;
        /* background: url('../assets/bg-layout.png') bottom no-repeat, linear-gradient(167.34deg, #FF4201 25.68%, #FF0077 65.68%);
        background-size: contain; */
    }
    .content-login-smart{
        background: url("../assets/backroundback.svg");
        background-size: cover;
        overflow: hidden;
        /* background: url('../assets/bg-layout.png') bottom no-repeat, linear-gradient(167.34deg, #FF4201 25.68%, #FF0077 65.68%);
        background-size: contain; */
    }
    .card-login{
        background-color: transparent;
        border: none;
        /* position: absolute; */
    /* top: 50%; */
    /* left: 50%; */
    transform: translate(0%, 9%);
    -webkit-transform: translate(0%, 9%);
    }
    .txt-white {
        color: white;
    }
    #form-input-otp {
  padding-left: 15px;
  letter-spacing: 42px;
  border: 0;
  background-image: linear-gradient(to left, black 70%, rgba(255, 255, 255, 0) 0%);
  background-position: bottom;
  background-size: 50px 1px;
  background-repeat: repeat-x;
  background-position-x: 35px;
  width: 220px;
}
    /* .max-35{
        max-width: 350px;
    } */
  .const-boot{
    position: absolute;
    left: 0px;
    bottom: 0px;
    width: 80vw !important;
    max-height: 424px;
    max-width: 341px;
    z-index: 101;
  }
</style>
