<template>
    <section>

    </section>
</template>
  
  <script>
  import {mapState, mapActions } from 'vuex'
  import router from '@/router'
  export default {
    name: "PID",
    data() {
      return {
        token:null,
        msisdn:null,
      };
    },
    mounted() {
      this.msisdn = this.$route.params.cmsisdn;
      this.$store.state.pid = this.msisdn;
      this.$store.state.user = this.msisdn;
      this.token = btoa(this.msisdn);
      this.$store.state.token = this.token;
        // if(this.user==null && this.pid==null){
        //     this.$store.state.pid = this.$route.params.cmsisdn
        //     this.goTo('login')
        // }else{
        //     this.goTo('home')
        // }
        // this.setStore();

        this.getSubscriptionStatusByFakeId().then(() => {
          // this.setStore();
          // if(mno_id)
          this.$store.state.telco='smart';
          this.$router.push({ name: "loading" });
        });
    },
    watch: {
    ...mapState([
      'telco',
      'secretKey',
      'pid'
    ]),
  },
    computed: {
    ...mapState([
      'pid',
      'user',
      'telco'
    ]),
    
  },
    methods: {
      goTo(page) {
        router.push({ name: page });
      },
      ...mapActions([
      'getSubscriptionStatusByFakeId',
      'getGames',
      ]),
      setStore() {
        this.$store.commit("SET_TOKEN", this.token);
        this.$store.commit("SET_USER", this.msisdn);
      },
    }
  };
  </script>
  
  <style scoped>
  </style>
  